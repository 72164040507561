.header  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $cream;
    position: sticky;
    top: 0px;
    border-radius: 70px;
    h1 {
        color: $dark-brown;
        font-size: 28px;
        margin: 5px 0 0 0;
    }
}

nav {
    margin-top: 20px;
    color: $ash-brown;
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.header--nav-service {
    height: 20px;
}

.header--nav-time {
    margin: 0;
}

.header--nav-location {
    height: 20px;
    margin: 0 8px 0 15px;
}

@media
(prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}