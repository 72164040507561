.main {
    background: url(../images/background.jpeg) no-repeat; 
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: $body-font;
}

.title {
    font-family: $acnh-font;
    font-size: 48px;
    color: $orange;
    margin: 24px 0 18px 0;
    text-shadow: 
        4px 4px 2px $chocolate-brown,
        7px 6px 2px $honey-brown;
}

.phone {
    background-color: $cream;
    width: 80%;
    max-width: 312px;
    height: 84%;
    min-height: 640px;
    max-height: 710px;
    margin: 0 0 8px 0;
    border-radius: 70px;
    box-shadow: 0px 2.9825618267059326px 7.456404209136963px 0px
        rgba(0, 0, 0, 0.1);
    position: relative;
}

.dev-credit {
    margin: 0 0 6px 0;
    font-size: 14px;
    color: #756B5D;
}

@media (max-height: 768px) {
    .phone {
        overflow: scroll;
        height: auto;
    }
}
