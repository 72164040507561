// Import Google fonts
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&display=swap');

// Define fonts for body and headers
$body-font: 'Baloo 2', cursive;
$headers-font: 'Baloo 2', cursive;

// From a font file
@font-face {
    font-family: "FinkHeavy";
    src: local("FinkHeavy"),
      url("./fonts/FinkHeavy.ttf") format("truetype");
    font-weight: normal;
}

$acnh-font: "FinkHeavy";