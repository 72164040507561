.form {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    input {
      padding: 6px 12px;
      margin: 0 0 0 5px;
      border-radius: 15px;
      width: 60%;
      font-size: 16px;
      font-weight: bold;
      color: $dark-brown;
      border: 1px solid $dark-brown;
      box-shadow: 0px 2.9825618267059326px 7.456404209136963px 0px
      rgba(0, 0, 0, 0.1);
    }
    ::placeholder {
      color: $ash-brown;
      font-weight: 400;
    }
    button {
      border: none;
      text-decoration: none;
      background: none;
      transition: all 0.3s ease-in-out;
      font-size: 28px;
      margin-left: 8px;
      color: $dark-brown;
    }
    button:active {
      transform: scale(1.3);
      text-shadow: 0px 2.9825618267059326px 7.456404209136963px 0px
      rgba(0, 0, 0, 0.1);
    }
  }
  